<template>
  <div>
    <el-breadcrumb separator-class="el-icon-arrow-right">
			<el-breadcrumb-item>保证管理</el-breadcrumb-item>
			<el-breadcrumb-item>保证申请</el-breadcrumb-item>
		</el-breadcrumb>
    <!-- 票据基本信息 -- 用的是工作台公共的子组件-里面包括票面预览和票面下载 -->
    <el-card>
      <WorkbenchPublicTitle :superInfoList="superInfoList">
        <template v-slot:titleText>
          <h3>票据基本信息</h3>
        </template>
      </WorkbenchPublicTitle>
      <el-table :data="superInfoList" border class="mt20">
        <el-table-column prop="packageNo" label="票据包号" min-width="150px"></el-table-column>
        <el-table-column prop="ticketRange" label="子票区间" min-width="130px"></el-table-column>
        <el-table-column prop="packageSum" label="票据包金额(元)" min-width="120px"></el-table-column>
        <el-table-column prop="acceptor" label="承兑人" min-width="150px"></el-table-column>
        <el-table-column prop="expireDate" label="票据到期日"></el-table-column>
        <el-table-column prop="applyDate" label="出票日期"></el-table-column>
        <el-table-column prop="ticketStatus" label="票据状态"></el-table-column>
      </el-table>
    </el-card>
    <!-- 保证信息 -->
    <el-card>
      <el-form :model="ensureInfoForm" 
               :rules="ensureInfoRules" 
               ref="ensureInfoRef" 
               label-width="160px"
               class="pledge-one-form">
        <el-form-item label="保证类型：">
          <span>{{ensureInfoForm.ensureType}}</span>
        </el-form-item>
        <el-form-item label="被保证人：">
          <span>{{ensureInfoForm.debtor}}</span>
        </el-form-item>
        <el-form-item label="保证人类型：" prop="creditorType">
          <el-radio-group v-model="ensureInfoForm.creditorType" @change="creditorTypeChange">
            <el-radio label="ENT">企业：</el-radio>
            <el-radio label="ORG">银行或财司：</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="保证金金额（元）：" prop="ensureAmt">
          <el-input v-model="ensureInfoForm.ensureAmt"></el-input>
        </el-form-item>
        <el-form-item label="保证人：" prop="creditor">
          <el-input v-model.trim="ensureInfoForm.creditor"  @input="creditorInput"></el-input>
        </el-form-item>
        <template v-if="isGuarantorAccount">
          <el-form-item label="保证人账号：" prop="acctNo">
            <el-select v-model="ensureInfoForm.acctNo"
                       placeholder="请选择"
                       @change="acctNoChange">
              <el-option v-for="(item,index) in accountList" 
                         key="index"
                         :label="item" 
                         :value="item"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="开户行：" prop="acctBankName">
            <span>{{ensureInfoForm.acctBankName}}</span>
          </el-form-item>
        </template>
        <el-form-item label="开户行行号：" prop="acctBankNo">
          <span>{{ensureInfoForm.acctBankNo}}</span>
        </el-form-item>
        <el-form-item label="备注：">
          <el-input type="textarea" v-model="ensureInfoForm.remark"></el-input>
        </el-form-item>
      </el-form>
    </el-card>
    <!-- 按钮 -->
    <div class="btn">
			<el-button @click="handleBack">返回</el-button>
			<el-button type="primary" @click="handleSubmit">确认提交</el-button>
		</div>

    <!-- 成功弹框 -->
		<el-dialog :visible.sync="successDialogVisible"
		           width="50%"
		           :show-close="false"
		           :close-on-click-modal="false"
		           @close="successDialogClosed">
			<div class="agree-dialog">
				<img src="~@/assets/img/ticket/cg.png" alt="">
				<h3>{{copyText}}</h3>
				<div class="copy-code">
					操作编号：<h4>{{copyCode}}</h4>
				</div>
				<el-button type="primary" @click="handleCopy">点击复制</el-button>
			</div>
			<span slot="footer" class="dialog-footer">
		    <el-button @click="successDialogVisible = false">关 闭</el-button>
		  </span>
		</el-dialog>
  </div>
</template>

<script>
  // 票面预览/票面下载
  import WorkbenchPublicTitle from "@/views/Ticket/routerCpn/workbench/publicCpn/WorkbenchPublicTitle";
  //按需引入lodash防抖函数
  import debounce from 'lodash/debounce'
  export default {
    name: 'GuaranteeApplyDetail',
    components: {
      WorkbenchPublicTitle
    },
    mounted(){
      // 票据id	--- 列表传过来的
      this.ticketId = this.$route.query.ticketId
      // 获取保证申请信息
      this.getInfo()
    },
    data(){
      return {
        ticketId: '', // 票据id	--- 列表传过来的
        superInfoList: [], // 公共组件 - 票面预览/下载
        accountList: [], // 保证人账号列表
        // 保证信息
        ensureInfoForm:{
          ensureType:'', // 保证类型：出票保证：CPE、承兑保证：CDE、背书保证：BSE
          debtor: '', // 被保证人
          creditorType: '', // 保证人类型：企业：ENT、银行或财司：ORG
          ensureAmt: '', // 保证金金额（元）
          creditor: '', // 保证人
          acctNo: '', //保证人账号
          acctBankName: '', // 开户行
          acctBankNo: '', // 开户行行号 
          remark: '' // 备注
        },
        // 保证信息表单验证规则
        ensureInfoRules: {
          creditorType: [
            { required: true, message: '请选择保证人类型', trigger: 'change' }
          ],
          ensureAmt: [
            { required: true, message: '请输入保证金金额', trigger: 'blur' },
          ],
          creditor: [
            { required: true, message: '请输入保证人', trigger: 'blur' },
          ],
          acctNo: [
            { required: true, message: '请选择保证人账号', trigger: 'change' }
          ],
          acctBankName: [
            { required: true, message: '开户行不能为空', trigger: 'blur' },
          ],
          acctBankNo: [
            { required: true, message: '开户行行号不能为空', trigger: 'blur' },
          ]
        },
        successDialogVisible: false, // 控制同意付款应答弹框的显示与隐藏
        copyCode: '', // 操作编号
        copyText: '', // 操作提示文字
      }
    },
    watch: {
      // 侦听保证信息 -- 获取保证人类型
      ensureInfoForm: {
        handler(value){
          if(value.creditorType == 'ORG'){
            this.ensureInfoForm.acctNo = ''
            this.ensureInfoForm.acctBankName = ''
          }
        },
        immediate: true,
        deep: true
      }
    },
    computed: {
      // 计算保证人类型 ---  企业/银行或财司
      isGuarantorAccount(){
        return this.ensureInfoForm.creditorType == 'ENT' ? true : false
      }
    },
    methods: {
      // 获取保证申请信息
      async getInfo(){
        const {data: res} = await this.$auth.get(`/cpiaoju-ticket/bz/mange/apply/info/${this.ticketId}`)
        if(res.code !== 200) return this.$message.error(res.msg)
        this.ensureInfoForm = res.data.ensureInfo
        this.superInfoList = res.data.ticketBaseInfoList
      },
      // 监听保证人的输入
      creditorInput: debounce(function(value){
        if(value){
          this.getAcctNo()
        }else{
          return this.$message.error('保证人不能为空')
        }
      },500),
      // 获取保证人账号
      async getAcctNo(){
        const {creditor, creditorType} = this.ensureInfoForm
        const {data: res} = await this.$auth.get(`/cpiaoju-ticket/bz/mange/accounts/${creditor}/${this.ticketId}/${creditorType}`)
        if(!res.data.accountList.length){
            this.accountList = []
            this.ensureInfoForm.acctNo = ''
            this.ensureInfoForm.acctBankName = ''
            this.ensureInfoForm.acctBankNo = ''
            return;
        }
        if(this.ensureInfoForm.creditorType == 'ENT'){
          this.accountList = res.data.accountList
        }else if(this.ensureInfoForm.creditorType == 'ORG'){
          this.ensureInfoForm.acctBankNo = res.data.accountList[0]
        }
      },
      // 监听选择保证人账号 -- 获取开户行和开户行行号
      async acctNoChange(value){
        const {data: res} = await this.$auth.get(`/cpiaoju-ticket/common/account/info/${value}`)
        if(res.code !== 200) return this.$message.error(res.msg)
        this.ensureInfoForm.acctBankName = res.data.acctBankName
        this.ensureInfoForm.acctBankNo = res.data.acctBankNo
        console.log(res)
      },
      // 监听保证人类型改变
      creditorTypeChange(value){
        if(value == 'ENT'){
          if(this.ensureInfoForm.creditor || this.ensureInfoForm.acctBankNo){
            this.ensureInfoForm.creditor = ''
            this.ensureInfoForm.acctBankNo = ''
          }
        }else if(value == 'ORG'){
          if(this.ensureInfoForm.creditor || this.ensureInfoForm.acctBankNo){
            this.ensureInfoForm.creditor = ''
            this.ensureInfoForm.acctBankNo = ''
          }
        }
      },
      // 返回
      handleBack(){
        this.$router.push({name: 'guaranteeApplyList'})
      },
      // 下一步
      handleSubmit(){
        this.$refs.ensureInfoRef.validate(async valid => {
          if(!valid) return
          let params = {
            ticketId: this.ticketId,
            debtor: this.ensureInfoForm.debtor,
            creditorType: this.ensureInfoForm.creditorType,
            ensureAmt: this.ensureInfoForm.ensureAmt,
            creditor: this.ensureInfoForm.creditor,
            acctNo: this.ensureInfoForm.acctNo,
            acctBankName: this.ensureInfoForm.acctBankName,
            acctBankNo: this.ensureInfoForm.acctBankNo,
            remark: this.ensureInfoForm.remark
          }
          const {data: res} = await this.$auth.post('/cpiaoju-ticket/bz/mange/apply/submit', this.$qs.stringify(params))
          if(res.code == 200){
            this.successDialogVisible = true
            this.copyCode = res.data.handlerNo
            this.copyText = res.data.text
          }else{
            this.successDialogVisible = false
            this.$message.error(res.msg)
          }
        })
      },
      // 成功弹框关闭
      successDialogClosed(){
        this.$router.push({name: 'guaranteeApplyList'})
      },
      // 点击复制
      handleCopy(){
        this.$copyText(this.copyCode).then(res => {
          this.$message.success('复制成功')
          this.successDialogVisible = false
          return false;
        },err => {
          this.$message.error('复制失败')
          this.successDialogVisible = true
        })
      },
    }
  }
</script>

<style lang="less" scoped>
  .pledge-one-form{
    width: 60%;
    .el-select{
      width: 100% !important;
    }
  }
  .btn{
    text-align: center;
    margin: 50px auto 20px;
    .el-button{
      min-width: 120px;
      margin: 0 30px;
    }
  }
  /*同意收票弹框*/
  /deep/ .agree-dialog{
    display: flex;
    flex-direction: column;
    align-items: center;
    h3{
      margin-top: 20px;
    }
    .copy-code{
      margin-top: 15px;
      h4{
        display: inline-block;
        color: #C70009;
      }
    }
    .el-button{
      margin: 30px 24px 0;
    }
  }
</style>